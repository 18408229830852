// import Header from '../Header'
import Home from '../Home'
import Map from '../Map'
import Features from '../Features'
import Contact from '../Contact'
import Gallery from '../Gallery'
import Footer from '../Footer'

function App() {
  return (
    <div className="App">
      {/* <Header /> */}
      <Home />
      <Map />
      <Features />
      <Gallery />
      <Contact />
      <Footer />
    </div>
  )
}

export default App
